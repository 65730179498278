// @import '@angular/material/_theming';
@use '@angular/material' as mat;
@import 'variables';
// @import 'mat-airme';
// // Include the base styles for Angular Material core. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// @include mat-core();
// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue.
// $airme-app-basic: mat-palette($mat-airme);
// $airme-app-accent:  mat-palette($mat-airme, A200, A100, A400);
// // The warn palette is optional (defaults to red).
// $airme-app-warn:    mat-palette($mat-airme);
// // Create the theme object (a Sass map containing all of the palettes).
// $airme-app-theme: mat-light-theme($airme-app-basic, $airme-app-accent, $airme-app-warn);
// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($airme-app-theme);
// $light-foreground-palette: map-get($airme-app-theme, foreground);
@import 'material-icons';
@import 'table';
@import 'material';
@import "~ag-grid-community/dist/styles/ag-grid.css";
// @import "~ag-grid-community/dist/styles/ag-theme-material.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import 'color-picker.scss';
@import '../../node_modules/angular-tree-component/dist/angular-tree-component.css';

html {
    /*font-size: $base-font-size;*/
    font-size: 14px;
}

// styles in src/style directory are applied to the whole page
body {
    color: #1b1b1b;
    /*font-family: Roboto, "Helvetica Neue", sans-serif;*/
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    background-color: mat.get-color-from-palette($airme-app-basic, A100);
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
}

html,
body {
    height: 100%;
    position: relative;
}

a {
    cursor: pointer;
}

.ok {
    color: $severity-ok;
}

.warn {
    color: $severity-warn;
}

.error {
    color: $severity-error;
}

header {
    color: $menu-text-color;
    background-color: #30333a /* mat-contrast($airme-app-basic, A300) */;
    height: $header-height;
    max-height: $header-height;
    .title {
        color: $accent-bg-color;
        padding-left: $menu-border-width;
        font-size: 1.2em;
        font-weight: 400;
    }
    .vertical-separator {
        display: inline-block;
        height: $header-height - 22px;
        width: 1px;
        background-color: mat.get-color-from-palette($airme-app-basic, A300);
        margin-top: 10px;
    }
    .right-menu {
        .vertical-separator {
            margin: 0;
        }
    }
    .right-menu {
        button {
            color: $menu-text-color;
            min-width: 40px;
            &.language {
                background-color: transparent;
                color: $secondary-text-color;
                &.mat-primary {
                    color: $menu-text-color;
                    background-color: $secondary-text-color;
                }
            }
        }
    }
}

main {
    .outlet-holder {
        background-color: $content-backgroud;
        padding: $content-padding-width;
        overflow: hidden;
    }
    &.isLoginPage {
        background-color: $main-backgroud;
        .outlet-holder {
            background-color: $main-backgroud;
        }
    }
}

.logo {
    background-image: url('/img/logo_orange.png');
    background-repeat: no-repeat;
    font-weight: bolder;
    display: inline-block;
    background-position: 5px 5px;
    &.header {
        background-image: url('/img/logo_grey.png');
        background-size: 60%;
        margin-top: 10px;
        margin-left: 8px;
        width: 155px;
    }
    &.login {
        background-image: url('/img/logo_grey.png');
        width: 160px;
        height: 60px;
        background-position: 50% 50%;
        background-size: 100%;
    }
}

.inlineList {
    display: flex;
    flex-direction: row;
    /* Below sets up your display method, ex: flex-start|flex-end|space-between|space-around */
    justify-content: flex-start;
    /* Below removes bullets and cleans white-space */
    list-style: none;
    /* Bonus: forces no word-wrap */
    white-space: nowrap;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    // padding-top: 5px;
    li {
        a {
            vertical-align: super;
        }
        &:last-child {
            font-weight: bolder;
        }
    }
}

.category-label {
    &.not-published {
        color: #999;
    }
}

.action-row-inner {
    .action-button {
        padding-right: 16px !important;

        &.has-actions {
            padding-right: 32px !important;
        }
    }

    .action-btn-arrow {
        display: inline-block;
        height: 35px;
        width: 21px;
        border: none;
        border-left: 1px solid #555;
        position: absolute;
        right: 6px;
        cursor: pointer;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.12);
            color: #333;
        }

        .mat-icon {
            width: 25px;
            line-height: 34px;
            padding-left: 1px;
        }
    }
}

.document-container {
    img {
        max-height: 300px;
    }
    .placeholder {
        color: $secondary-text-color;
    }
}

.toggle-menu-btn {
    cursor: pointer;
    position: relative;
    padding-top: 15px;
    margin-left: 13px;
    transition: all .3s ease-in-out;
    &.expanded {
        transition: all .3s ease-in-out;
        transform: scaleX(-1);
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

%thumb-48 {
    width: 48px;
    height: 48px;
}

%thumb-32 {
    width: 32px;
    height: 32px;
}

.document-icon {
    display: block;
    &.thumb-48- {
        &png {
            @extend %thumb-48;
            background-image: url('#{$file-icons-48-path}/png.png');
        }
        &jpg {
            @extend %thumb-48;
            background-image: url('#{$file-icons-48-path}/jpg.png');
        }
        &bmp {
            @extend %thumb-48;
            background-image: url('#{$file-icons-48-path}/bmp.png');
        }
        &pdf {
            @extend %thumb-48;
            background-image: url('#{$file-icons-48-path}/pdf.png');
        }
        &text {
            @extend %thumb-48;
            background-image: url('#{$file-icons-48-path}/txt.png');
        }
        &word {
            @extend %thumb-48;
            background-image: url('#{$file-icons-48-path}/doc.png');
        }
        &excel {
            @extend %thumb-48;
            background-image: url('#{$file-icons-48-path}/xls.png');
        }
        &powerpoint {
            @extend %thumb-48;
            background-image: url('#{$file-icons-48-path}/ppt.png');
        }
        &unknown {
            @extend %thumb-48;
            background-image: url('#{$file-icons-48-path}/_blank.png');
        }
    }
    &.thumb-32- {
        &png {
            @extend %thumb-32;
            background-image: url('#{$file-icons-32-path}/png.png');
        }
        &jpg {
            @extend %thumb-32;
            background-image: url('#{$file-icons-32-path}/jpg.png');
        }
        &bmp {
            @extend %thumb-32;
            background-image: url('#{$file-icons-32-path}/bmp.png');
        }
        &pdf {
            @extend %thumb-32;
            background-image: url('#{$file-icons-32-path}/pdf.png');
        }
        &text {
            @extend %thumb-32;
            background-image: url('#{$file-icons-32-path}/txt.png');
        }
        &word {
            @extend %thumb-32;
            background-image: url('#{$file-icons-32-path}/doc.png');
        }
        &excel {
            @extend %thumb-32;
            background-image: url('#{$file-icons-32-path}/xls.png');
        }
        &powerpoint {
            @extend %thumb-32;
            background-image: url('#{$file-icons-32-path}/ppt.png');
        }
        &unknown {
            @extend %thumb-32;
            background-image: url('#{$file-icons-32-path}/_blank.png');
        }
    }
}

.dialog-errors {
    li {
        color: $severity-error;
    }
}

.button-row {
    text-align: right;
    padding: 5px 0;
    align-self: center;
}

.pointer {
    cursor: pointer;
}

.separator {
    height: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin: 12px 0;
    width: 100%;
    line-height: 1px;
}

.version {
    bottom: 0px;
    position: absolute;
    right: 8px;
    color: rgba(255, 255, 255, 0.35);
    .release-icon {
        color: rgba(255, 255, 255, 0.35);
        font-size: 13px !important;
        position: relative;
        top: 1px;;
    }
}

textarea {
    &.editor {
        font-family: Lucida Console, Courier, monospace;
        font-size: 12px;
        &.elastic {
            height: 150px;
        }
    }
}

.text-search-input {
    padding-right: 15px;
}

.vertical-center {
    margin-top: auto;
    margin-bottom: auto;
}

.disable-scroll {
    height: 100%;
    overflow: hidden;
}

.fixed-panel {

    width: 100%;
    height: 100%;
    flex: 1;

    .scroll-content {
        display: flex;
        width: 100%;
        height: calc(100% - 40px);
    }

    .scroll-items {
        overflow: auto;
        max-height: calc(100% - 310px);
    }
}

.info-button {
    text-decoration: none;
    color: $secondary-text-color;
    cursor: pointer;
    &:hover {
        color: $primary-text-color;
    }
}

label {
    
    color: $table-header-font-color;
}

.login-wrap label {
    
    color: rgba(255, 255, 255, 1) !important;
}

.btn-azure-login {
    min-height: 35px;
    background-color: white;
    cursor: pointer;
    margin-top: 20px;

    .logo {
        background-image: url('/img/ms-logo.png');
        background-repeat: no-repeat;
        margin-top: 2px;
        min-width: 34px;
    }

    .login-text {
        font-weight: bold;
        color: var(--text-hint);
    }

    &:hover {
        .login-text {
            color: var(--text-color);
        }
    }
}

.mat-menu-panel {
    max-width: 480px;
    min-height: unset !important;
}

.popover-menu {
    .mat-raised-button {
        text-align: left;
    }
}

.mat-card-header {
    .mat-card-header-text {
        flex-grow: 1;
        margin: 0;
        .mat-card-title {
            flex: 1 1 100%;
            justify-content: space-between;
            display: flex;
        }
    }
}

section {
    &.container {
        margin: 6px 0;
    }
}

.wallet {
    margin: 5px;
}

.wallet-phones {
    background-color: $main-backgroud;
}

.param-list {

    &.mat-table {
        display: flex;
        flex: 1 0 100%;
        overflow: hidden;
        flex-direction: column;

        .mat-row {
            display: flex;
            flex: 1 0 100%;
            overflow: hidden;

            .mat-column-value {
                align-self: start;
            }
        }
    }

}

.inline-editor {
    @include mat.elevation(2);
    display: block;
    background: #f9f9f9;
    border-radius: 2px;
    padding: 16px 24px 8px;
    min-width: 240px;
      
      .mat-subheading-2 {
        margin: 0;
        font-weight: 500;
      }
      
      .mat-form-field {
        width: 100%;
        font-size: 14px;
      }
      
      .actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
        margin-right: -16px;
      }
}

/* IE hack */

@media all and (-ms-high-contrast:none) {
    .content {
        padding-right: $menu-width;
    }
    /* IE10 */
    *::-ms-backdrop,
    .content {
        padding-right: $menu-width;
    }
    /* IE11 */
    /* IE10+ CSS styles go here */
    .mat-dialog-container {
        min-width: 405px;
        overflow: visible;
        mat-dialog-content {
            overflow: visible;
        }
    }
    .ie-error {
        display: block;
        font-size: 10px;
        padding-left: 10px;
        padding-right: 8px;
        color: $severity-error;
    }
}

hc-localization-list,
hc-country-list,
hc-setting-product-menu-list {
    overflow: auto;
    width: 100%;
}

.cdk-overlay-backdrop {
    overflow: hidden;
}

.divider {
    height: 1px;
    display: flex;
    flex: 1 1 calc(100% - 20px);
    background-color: #e7e7e7;
    opacity: 0.2;
}

// fxFlex indlect display: flex into child elements. Sometimes you have to omit this feature
[disableFlex], #disableFlex {
    display: block !important;
}

/*
@media screen and (min-width: 320px) {
    html {
        font-size: calc(9px + 8 * ((100vw - 320px) / 960));
    }
}

@media screen and (min-width: 1280px) {
    html {
        font-size: $base-font-size;
    }
}
*/

[hidden] {
    display: none !important;
}

.sw {
    &.btn {
        height: 5px;
        width: 5px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: crosshair;
    }
    &.sw-content {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        z-index: 1;
        overflow: hidden;
        .fade {
            position: relative;
            width: 100%;
            min-height: 60vh;
            top: -25px;
            background-image: linear-gradient(0deg, transparent, black 75%);
            z-index: 5;
        }
        .star-wars {
            top: 0;
            display: flex;
            justify-content: center;
            position: relative;
            height: 100vh;
            color: #feda4a;
            font-family: 'Pathway Gothic One', sans-serif;
            font-size: 500%;
            font-weight: 600;
            letter-spacing: 6px;
            line-height: 150%;
            perspective: 600px;
            text-align: justify;
            z-index: 2;
        }
        .crawl {
            position: relative;
            top: 99999px;
            transform-origin: 50% 100%;
            animation: crawl 60s linear;
            z-index: 4;
        }
        .crawl>.title {
            font-size: 90%;
            text-align: center;
        }
        .crawl>.title h1 {
            margin: 0 0 100px;
            text-transform: uppercase;
        }
        @keyframes crawl {
            0% {
                top: -100px;
                transform: rotateX(20deg) translateZ(0);
            }
            100% {
                top: -6000px;
                transform: rotateX(25deg) translateZ(-2500px);
            }
        }
    }
}

.report-grid-numeric-cell {
    text-align: right;
}

.report-grid-date-cell {
    text-align: right;
}

.report-grid-detail-cell {
    text-decoration: underline;
    cursor: pointer;
}

.sub-menu-button {
    padding-left: 26px !important;
}

.send-dialog {
    min-width: 50vw;
    overflow: hidden;

    .iframe-wrapper {
        min-height: 600px;
    }

    .image-container {
        text-align: center;
        width: 100%;
        height: 100%;
    }

    .mat-spinner {
        margin-left: auto;
        margin-right: auto;
    }
}

hc-angular-rich-editor {
    width: 100%;
    padding-bottom: 10px;
    .angular-editor {
        position: relative;
        padding-top: 15px;

        .editor-label {
            color: rgba(0, 0, 0, 0.54);
            cursor: default;
            display: block;
            font-size: 12px;
            font-stretch: 100%;
            font-style: normal;
            font-weight: 400;
            height: 13px;
            left: 0px;
            line-height: 13.5px;
            pointer-events: none;
            position: absolute;
            text-align: left;
            text-overflow: ellipsis;
            top: 0px;
            white-space: nowrap;
        }
    }

    .angular-editor-wrapper {
        min-height: 100px;
        max-height: 450px;
        overflow: auto;
        border: 1px solid #ccc;
        position: relative;
        cursor: pointer;

        &.hide-controls {
            min-height: auto;
            border: none;
        }

        .edit-icon {
            position: absolute;
            display: none;
            top: 0px;
            right: 0px;
        }

        &:hover {
            .edit-icon {
                position: absolute;
                display: block;
                text-align: right;
            }
        }

        
    }

    
}

.html-editor-frame {
    width: calc(80vw - 48px);
    height: calc(65vh - 2px);
    overflow-y: hidden;
    overflow-x: hidden;
    border: 1px solid $focus-color;
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.placeholder-input {
    border: 0;
	border-radius: 4px;
	color: #111;
	font-size: 16px;
	line-height: 16px;
	outline: 0;
	padding: 10px 12px;
	position: relative;
	text-align: left;
	width: 230px;
	&::-webkit-input-placeholder {
		color: #cfcfcf;
		text-align: center;
	}
	&:-ms-input-placeholder {
		color: #cfcfcf;
		text-align: center;
	}
	&::-ms-input-placeholder {
		color: #cfcfcf;
		text-align: center;
	}
	&::placeholder {
		color: #cfcfcf;
		text-align: center;
    }
    
    &__marker {
        background-color: #111;
        border-radius: 4px;
        box-shadow: 0 2px 5px #585858;
        color: #cfcfcf;
        display: block;
        font-size: 12px;
        padding: 4px 6px;
        position: absolute;
        -webkit-transform: translate(5%, -70%);
        transform: translate(5%, -70%);
        transition: top 0.1s ease 0s, left 0.1s ease 0s;
        white-space: nowrap;
        width: auto;
        z-index: 9999;
        &:after {
            background-color: #111;
            content: '';
            height: 10px;
            position: absolute;
            width: 15px;
            z-index: -1;
        }

        &--position {
            &:after {
                bottom: 0;
                left: 0;
                -webkit-transform: translate(-10%, 10%) rotate(-15deg) skewX(-40deg);
                transform: translate(-10%, 10%) rotate(-15deg) skewX(-40deg);
            }
        }

        &--selection {
            -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
            &:after {
                bottom: 0;
                left: 50%;
                -webkit-transform: translate(-50%, 0) rotate(45deg);
                transform: translate(-50%, 0) rotate(45deg);
            }
        }

        &--custom {
            -webkit-transform: translate(16px, 16px);
            transform: translate(16px, 16px);
            &:after {
                display: none;
            }
        }
    }
}

.custom-suggestions {
    cursor: pointer;
    list-style: none;
    margin: 0;
    padding: 0;
  
  &--active {
    background-color: $accent-bg-color;
    color: $table-header-font-color;
  }
  li {
    text-align: left;
    padding: 1px;
    font-weight: bold;
    border-radius: 2px;
    &:hover {
        background-color: $accent-bg-color;
        color: $table-header-font-color;
      }
    }
}

.aggrid-editor {
    input, select {
        height: 26px;
    }
}

.dimension-editor {
 input {
     width: 55px;
     height: 20px;
 }   
}

iframe {
    border: 0px;
}